<script>
import { wpService } from "@/services/wp";
import { mapGetters } from "vuex";
import _merge from "lodash/merge";
import _map from "lodash/map";
import orgInfoEditor from "@/components/org-info-editor";

export default {
  name: "acc-org",
  components: {
    "org-hero": () =>
      import(
        /* webpackChunkName: "org-hero" */ "@/components/organization/org-hero.vue"
      ),
    "acc-org-members": () =>
      import(
        /* webpackChunkName: "acc-org-members" */ "@/components/acc-org-members.vue"
      ),
  },

  metaInfo: {
    title: "Organización",
  },

  data() {
    return {
      fetched: false,
      tabs: [
        {
          title: "Perfil",
          name: "profile",
        },
        {
          title: "Miembros",
          name: "members",
        },
        {
          title: "Oficinas",
          name: "offices",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("session", ["accOrgData", "org_member_role"]),
    CAN_EDIT_ORG_PROFILE() {
      return this.accOrgData.member_can.includes("EDIT_ORG_PROFILE");
    },
    currentTab() {
      return this.$route.query.tab || "profile";
    },
    validTabs() {
      return _map(this.tabs, "name");
    },
    sections() {
      const sections = _merge([], this.tabs);
      // const baseRoute = this.$router.resolve({ name: "acc-org" });
      for (let index = 0; index < sections.length; index++) {
        // sections[index].route = `acc-org-${sections[index].name}`;
        // sections[index].route = `${baseRoute.href}/${sections[index].name}`;
        sections[index].route = {
          query: {
            tab: sections[index].name,
          },
        };
      }
      return sections;
    },
    hasHero() {
      if (this.accOrgData.org_cover) {
        return this.accOrgData.org_cover;
      }
      return {
        src:
          "https://media-exp1.licdn.com/dms/image/C4D1BAQGZsd3tHmWGuA/company-background_10000/0/1622815356750?e=1625281200&v=beta&t=MFznckQomWXrsho-kVROJYZEbHjV7B9CHvUAQl6sHbc",
      };
      // return false;
    },
  },

  async created() {
    if (!this.validTabs.includes(this.currentTab)) {
      this.$router.replace({ name: "acc-org" });
    }
    await this.refreshAccount();
  },

  methods: {
    openInfoEditor() {
      this.$buefy.modal.open({
        parent: this.$root,
        customClass: "orgInfoEditor_modal",
        // hasModalCard: true,
        component: orgInfoEditor,
      });
    },
    async refreshAccount() {
      console.log("refreshAccount");
      return wpService.account.getData().finally(() => {
        this.fetched = true;
      });
    },
  },
};
</script>

<template>
  <sitio-layout id="acc-org">
    <div v-if="!fetched" class="page_gap">
      <b-loading class="pageLoader" :active="true" :is-full-page="false" />
    </div>
    <div v-else-if="accOrgData" class="page_gap content_width">
      <div class="page__header">
        <div class="content">
          <h1 class="page__title">Tu Organización</h1>
          <p>
            Formas parte de la organización
            <b>{{ accOrgData.org_name }}</b> bajo el rol de
            <b>[{{ org_member_role.title }}].</b>
          </p>
          <div class="buttons">
            <b-button size="is-small" type="is-danger"
              >Abandonar Organización</b-button
            >
            <b-button size="is-small" type="is-danger"
              >Eliminar Organización</b-button
            >
          </div>
        </div>
      </div>
      <div class="tabs">
        <ul>
          <li
            v-for="section in sections"
            :key="section.name"
            :class="{ 'is-active': section.name === currentTab }"
          >
            <router-link class="tab" :to="section.route">{{
              section.title
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="profile_head">
        <org-hero
          v-if="hasHero"
          :value="hasHero.src"
          class="head_hero"
        ></org-hero>
        <div class="head_content">
          <div class="head_info">
            <div class="head_card">
              <div class="org_avatar">
                <img
                  v-if="!accOrgData.org_avatar"
                  src="@/assets/avatar-square.svg"
                />
                <img
                  v-if="accOrgData.org_avatar"
                  :src="accOrgData.org_avatar.src"
                  :srcset="accOrgData.org_avatar.srcset"
                />
              </div>
              <div class="fields">
                <h1 class="org_name">{{ accOrgData.org_name }}</h1>
                <p class="org_address">{{ accOrgData.org_address }}</p>
              </div>
            </div>
            <p class="org_punchline">{{ accOrgData.org_punchline }}</p>
          </div>
          <div class="head_options">
            <div class="buttons">
              <b-button
                v-if="CAN_EDIT_ORG_PROFILE"
                type="is-link"
                class=""
                @click="openInfoEditor"
                >Editar</b-button
              >
            </div>
          </div>
          <!-- <p class="website">{{ accOrgData.org_website }}</p> -->
        </div>
      </div>
      <!-- <section class="block"><b>Estadísticas</b></section> -->
      <section v-if="currentTab === 'profile'" class="block">
        <!-- <b>Perfíl</b> -->
        <div class="data__list">
          <!-- <div class="data__field">
            <div class="data__label">Imagen de cabecera</div>
            <div class="data__value"></div>
          </div> -->
          <!-- <div class="data__field">
            <div class="data__label">Avatar</div>
            <div class="data__value">
              <div class="avatar">
                <div class="avatar-container">
                  <img
                    class="size-holder"
                    src="@/assets/sizeholder-square.png"
                  />
                  <picture v-if="accOrgData.org_avatar">
                    <source :srcset="accOrgData.org_avatar.srcset" />
                    <img :src="accOrgData.org_avatar.src" />
                  </picture>
                  <picture v-else>
                    <img src="@/assets/avatar-square.svg" />
                  </picture>
                </div>
              </div>
            </div>
          </div> -->
          <div class="data__field">
            <div class="data__label">
              URL SLUG
            </div>
            <div class="data__value">
              <router-link
                :to="{
                  name: 'organization-single',
                  params: { org_slug: accOrgData.org_slug },
                }"
                >https://sitio.do/orgs/{{ accOrgData.org_slug }}</router-link
              >
            </div>
          </div>
          <div class="data__field">
            <div class="data__label">Nombre de la organización</div>
            <div class="data__value">{{ accOrgData.org_name }}</div>
          </div>
          <!-- <div class="data__field">
              <div class="data__label">RNC</div>
              <div class="data__value">{{ accOrgData.org_rnc }}</div>
            </div> -->
          <div class="data__field">
            <div class="data__label">Dirección</div>
            <div class="data__value">{{ accOrgData.org_address }}</div>
          </div>
          <div class="data__field">
            <div class="data__label">Teléfono principal</div>
            <div class="data__value">{{ accOrgData.org_phone }}</div>
          </div>
          <!-- <div class="data__field">
              <div class="data__label">Correo electrónico de contacto</div>
              <div class="data__value">{{ accOrgData.org_email }}</div>
            </div> -->
          <div class="data__field">
            <div class="data__label">Punchline</div>
            <div class="data__value">{{ accOrgData.org_punchline }}</div>
          </div>
          <!-- <div class="data__field">
              <div class="data__label">Descripción</div>
              <div class="data__value">{{ accOrgData.org_description }}</div>
            </div> -->
          <div class="data__field">
            <div class="data__label">Website</div>
            <div class="data__value">{{ accOrgData.org_website }}</div>
          </div>
          <div class="data__field">
            <div class="data__label">Facebook</div>
            <div class="data__value">{{ accOrgData.org_sm_facebook }}</div>
          </div>
          <div class="data__field">
            <div class="data__label">Instagram</div>
            <div class="data__value">{{ accOrgData.org_sm_instagram }}</div>
          </div>
          <div class="data__field">
            <div class="data__label">LinkedIn</div>
            <div class="data__value">{{ accOrgData.org_sm_linkedin }}</div>
          </div>
          <div class="data__field">
            <div class="data__label">Twitter</div>
            <div class="data__value">{{ accOrgData.org_sm_twitter }}</div>
          </div>
        </div>
      </section>
      <section v-else-if="currentTab === 'members'" class="block">
        <b>Miembros</b> - Administración de miembros y roles
        <hr />
        <acc-org-members :org_id="accOrgData.org_id" />
      </section>
      <section v-else-if="currentTab === 'offices'" class="block">
        <b>Oficinas/Sucursales</b>
        <hr />
      </section>
    </div>
    <div v-else class="page_gap content_width">
      <div class="page_layout">
        <div class="page__header">
          <h1 class="page__title">{{ this.$options.metaInfo.title }}</h1>
        </div>

        <div class="section_block">
          <p>Actualmente no perteneces a ninguna organización.</p>
        </div>

        <div class="section_block content">
          <h3 class="section_title">Invitaciones</h3>
          <p>
            Actualmente no tienes invitaciones para unirte a una organización.
          </p>
        </div>

        <div class="section_block content create_org">
          <h3 class="section_title">Crea tu organización</h3>
          <img src="https://via.placeholder.com/640x320/" />
          <div class="content">
            <p>
              Crea tu propia organización y disfruta de todos los beneficios y
              herramientas que Sitio tiene para tu negocio.
            </p>
            <ul>
              <li>Perfil profesional para organización personalizable.</li>
              <li>Motor privado de busqueda de propiedades.</li>
              <li>Administración de miembros y roles.</li>
              <!-- <li>Cartera de créditos para miembros</li> -->
              <li>Intercambio de créditos entre miembros.</li>
              <li>mucho más...</li>
            </ul>
            <p><b>Costo:</b> 100 créditos/año</p>
            <b-button type="is-link">Crear organización</b-button>
          </div>
        </div>
      </div>
    </div>
  </sitio-layout>
</template>

<style lang="scss">
html[route^="acc-org"] {
  background-color: var(--sitio-bg-semi);
}
</style>

<style lang="scss" scoped>
// .content_width {
//   max-width: 34rem;
// }

.pageLoader {
  z-index: calc(var(--sitio-nav-index) - 1);
}

.page__header {
  margin-bottom: 1.5rem;
}
.page_layout {
  position: relative;
  width: 100%;
}
.section_block {
  padding: 1.25rem;
  // border: 1px solid #ddd;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: var(--sitio-artdeco-shadow);
  background-color: #fff;
}
.section_title {
  font-size: 1rem;
}
.data__list {
  .data__label {
    font-weight: 500;
  }
}
.avatar {
  width: 10rem;
  .avatar-container {
    overflow: hidden;
    position: relative;
  }
  .size-holder {
    width: 100%;
    display: block;
  }
  picture {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    overflow: hidden;
    img {
      display: block;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
}

.block {
  background-color: #fff;
  padding: 1rem var(--sitio-spacing);
  box-shadow: var(--sitio-artdeco-shadow);
}

.profile_head {
  margin: 0 auto;
  max-width: var(--sitio-max-content-width);
  box-shadow: var(--sitio-artdeco-shadow);
  .head_hero {
    // height: 20vmin;
    max-height: 30vmin;
    overflow: hidden;
    display: flex;
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .head_content {
    background-color: #fff;
    padding: 1rem var(--sitio-spacing);
    display: flex;
    gap: 1rem;
  }
  .head_info {
    flex: 1 1 auto;
  }
  .head_options {
    text-align: right;
  }
  .head_card {
    display: flex;
    align-items: flex-end;
    .fields {
      margin-left: 1rem;
    }
  }
  .org_avatar {
    // height: 0;
    img {
      display: block;
      border: 2px solid #fff;
      // transform: translateY(-100%);
      width: 4.25rem;
      height: 4.25rem;

      box-shadow: var(--sitio-artdeco-shadow);
    }
  }
  .head_hero + .head_content {
    .org_avatar {
      height: 0;
      img {
        transform: translateY(-100%);
      }
    }
  }
  .org_name {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .org_address {
    font-size: 0.875rem;
  }
  .org_punchline {
    margin-top: 0.75rem;
    font-weight: 500;
    color: #333;
  }
  @media (max-width: 640px) {
    .head_content {
      flex-direction: column;
    }
  }
  @media (min-width: 40em) {
    .org_avatar {
      img {
        width: 7rem;
        height: 7rem;
      }
    }
    .org_name {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 799px) {
  .data__list {
    .data__field {
      margin-bottom: 1rem;
    }
    .data__label {
      font-size: 0.9375rem;
    }
  }
}

@media (min-width: 800px) {
  .content_width {
    max-width: 60rem;
  }
  .page_layout {
    padding-right: 26rem;
  }
  .create_org {
    position: absolute;
    width: 24rem;
    right: 0;
    top: 0;
    overflow: hidden;
  }
  .data__list {
    display: table;
    width: 100%;
    .data__field {
      display: table-row;
    }
    .data__label,
    .data__value {
      display: table-cell;
      padding: 0.5em 0.75em;
      vertical-align: top;
    }
  }
}
@media (min-width: 1128px) {
  .content_width {
    max-width: 70rem;
  }
}
</style>
